import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/index.css'
import store from './store'
import router from './router'
const feather = require('feather-icons');

if (!localStorage.getItem('theme')) {
	localStorage.setItem('theme', 'dark')
}

feather.replace();

const args = process.argv.slice(2);
console.log(args);

const app = createApp(App)
app.config.globalProperties.$lang = 'en'
app.use(store).use(router).mount('#app')



const appTheme = localStorage.getItem('theme');

// Check what is the active theme and change theme when user clicks on the theme button in header.
if (
	appTheme === 'dark' &&
	document.querySelector('body').classList.contains('app-theme')
) {
	document.querySelector('body').classList.add('bg-primary-dark');
} else {
	document.querySelector('body').classList.add('bg-slate-50');
}