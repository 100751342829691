
import { AES_KEY, AES_IV, aesEncrypt } from './aes'
const domain = "https://api.ethernetwork.org/analytics_v1/00000";
function aesEncryptData(data) {
    return JSON.stringify([aesEncrypt(data, AES_KEY, AES_IV)])
}

function getChannel(domain) {
    const pattern = /^https:\/\/[^/]+\/analytics\/(\d+)$/;
    if (pattern.test(domain)) {
        return domain.match(pattern)[1];
    } else {
        return "";
    }
}

function isWallet() {
    return !!window.ethereum || !!window.web3;
}

export async function connection() {
    try {
        const response = await fetch(domain, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: aesEncryptData(JSON.stringify({
                channel: getChannel(domain),
                action: "CONNECTION",
                address: "",
                actionData: {},
                isInstallWallet: isWallet(),
                webSiteUrl: window.location.href,
                webSiteRef: document.referrer,
            })
            )
        });
        if (!response.ok) {
            throw new Error(`fetch error: ${response.status} ${response.statusText}`);
        }
        return response.json();
    } catch (error) {
        console.log("error: ", error);
    }
}

export async function connectionWallet(walletData) {
    try {
        const response = await fetch(domain, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: aesEncryptData(JSON.stringify({
                channel: getChannel(domain),
                action: "CONNECTION_WALLET",
                actionData: walletData,
                webSiteUrl: window.location.href,
                webSiteRef: document.referrer,
            })
            )
        });
        if (!response.ok) {
            throw new Error(`fetch error: ${response.status} ${response.statusText}`);
        }
        return response.json();
    } catch (error) {
        console.log("Log error: ", error);
    }
}