<template>
  <div :class="appTheme" class="pt-0.5">
    <AppHeader />

    <transition name="fade" mode="out-in">
			<router-view :theme="appTheme" />
		</transition>
    
  </div>
</template>

<script>
import AppHeader from './components/AppHeader';
import feather from 'feather-icons';
import { connection } from './utils/report-info';

export default {
  name: 'App',
  metaInfo: {
    title: 'Snapshot OneKey voting,Vote multiple EVM propsals in one single shot,Snapshot automatic voting,Snapshot decentralized on-chain governance,ETH voting,ARB voting,OP voting.',
    meta: [
      { name: 'description', content: 'Snapshot OneKey voting,Vote multiple EVM propsals in one single shot,Spaces currently supported: Uniswap, Sushi, Hop, Gnosis, 1inch, Gitcoin, ENS, AAVE, Stargate DAO, Arbitrum Odyssey, Optimism, Curve, Shell Protocol, dYdX, Balancer, Simon DAO, and many more' },
      { property: 'og:title', content: 'Snapshot OneKey voting,Vote multiple EVM propsals in one single shot,Snapshot automatic voting,Snapshot decentralized on-chain governance,ETH voting,ARB voting,OP voting.' }
    ]
  },
  components: {
		AppHeader,
	},
  data: () => {
		return {
			appTheme: localStorage.getItem('theme'),
		};
	},
	mounted() {
		feather.replace();
    connection();
	},
	updated() {
		feather.replace();
	},
}
</script>