export const spacesList = [
    {
        id: "uniswap",
        name: 'Uniswap',
    },
    {
        id: "sushigov.eth",
        name: 'Sushi',
    },
    {
        id: "hop.eth",
        name: "Hop Exchange"
    },
    {
        id: "gnosis.eth",
        name: "GnosisDAO"
    },
    {
        id: "1inch.eth",
        name: "1inch Network"
    },
    {
        id: "gmx.eth",
        name: "GMX"
    },
    {
        id: "ens.eth",
        name: "ENS"
    },
    {
        id: "aave.eth",
        name: "AAVE"
    },
    {
        id: "stgdao.eth",
        name: "Stargate DAO"
    },
    {
        id: "arbitrum-odyssey.eth",
        name: "Arbitrum Odyssey"
    },
    {
        id: "opcollective.eth",
        name: "Optimism Collective"
    },
    {
        id: "curve.eth",
        name: "Curve Finance"
    },
    {
        id: "shellprotocol.eth",
        name: "Shell Protocol"
    },
    {
        id: "dydxgov.eth",
        name: "dYdX"
    },
    {
        id: "balancer.eth",
        name: "Balancer"
    },
    {
        id: "sismo.eth",
        name: "Sismo"
    },
    {
        id: "layer2dao.org",
        name: "Layer2 DAO"
    },
    {
        id:"lido-snapshot.eth",
        name:"Lido"
    },
    {
        id:"people-dao.eth",
        name:"PeopleDAO"
    },
    {
        id:"cow.eth",
        name:"CoW DAO"
    },
    {
        id:"safe.eth",
        name:"SafeDAO"
    },
    {
        id:"starknet.eth",
        name:"Starknet"
    },
    {
        id:"arbitrumfoundation.eth",
        name:"Arbitrum DAO"
    },
    {
        id:"zkapedao.eth",
        name:"zkApes DAO"
    },
    {
        id:"cakevote.eth",
        name:"PancakeSwap"
    },
    {
        id:"gal.eth",
        name:"Galxe"
    },
    {
        id:"aavegotchi.eth",
        name:"Aavegotchi"
    },
    {
        id:"speraxdao.eth",
        name:"Sperax DAO"
    },
    {
        id:"snapshot.dcl.eth",
        name:"Decentraland"
    },
    {
        id:"apecoin.eth",
        name:"ApeCoin DAO"
    },
    {
        id:"ipistr.eth",
        name:"Shorter Finance"
    },
    {
        id:"poh.eth",
        name:"Proof Of Humanity"
    },
    {
        id:"trustwallet",
        name:"Trust Wallet"
    },
    {
        id:"mail3.eth",
        name:"Mail3"
    },
    {
        id:"thegurudao.eth",
        name:"GuruDAO Bootstrap Phase"
    },
    {
        id:"vestafinance.eth",
        name:"Vesta"
    },
    {
        id:"radiantcapital.eth",
        name:"Radiant Capital"
    },
    {
        id:"olympusdao.eth",
        name:"OlympusDAO"
    },
    {
        id:"bestfork.eth",
        name:"Wonderland"
    },
    {
        id:"moonbeam-foundation.eth",
        name:"Moonbeam Foundation"
    },
    {
        id:"badgerdao.eth",
        name:"BadgerDAO"
    },
    {
        id:"qidao.eth",
        name:"Qi Dao | Mai.Finance"
    },
    {
        id:"mocana.eth",
        name:"Moca DAO"
    },
];
